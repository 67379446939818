import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mentoring',
  templateUrl: './mentoring.component.html',
  styleUrls: ['./mentoring.component.scss']
})
export class MentoringComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
